import { CONFIG_APP } from '@/shared/config';
import { CookieService } from '../../cookie/CookieService';
import { ProcessAdapter } from '../store/adapter';
import { AuthModel } from '../types';

export class ProcessController {
  private adapter: ProcessAdapter;

  constructor() {
    this.adapter = new ProcessAdapter();
  }

  public saveTemplateNods(nodes: any) {
    this.adapter.saveTemplateNods(this.fomattedProcessNodsStr(nodes));
  }

  public saveNodes(nodes: string[]) {
    return this.adapter.saveNodes(nodes);
  }

  public updateAuth(auth: AuthModel) {
    this.adapter.updateAuth(auth);
  }
  
  public updateTokens(tokens: { accessToken: string; refreshToken: string }) {
    this.adapter.updateTokens(tokens);
  }

  public spliteItem(nodesItem: string) {
    return nodesItem.split(' ')[0];
  }

  private fomattedProcessNodsStr(nodesString: string) {
    const result: string[] = [];
    const mapSplitArray = new Map();

    mapSplitArray.set('splitArr', nodesString.split(' \n'));

    const splitNodesArray = mapSplitArray.get('splitArr');

    for (let i = 0; i < splitNodesArray.length; i++) {
      if (splitNodesArray[i].length < 4) continue;
      result.push(this.spliteItem(splitNodesArray[i]));
    }

    mapSplitArray.clear();
    return result;
  }

  public getAdjacentSteps() {
    return this.adapter.getAdjacentSteps();
  }

  public getCurrentAndPrevStep() {
    return this.adapter.getCurrentAndPrevStep();
  }

  public searchStep(value: number) {
    return this.adapter.searchStep(value);
  }

  public resetProcess() {
    return this.adapter.resetProcess();
  }

  public setSpeed(value: number) {
    return this.adapter.setSpeed(value);
  }

  public setPlay(value: boolean) {
    return this.adapter.setPlay(value);
  }

  public changeStep(value: number) {
    return this.adapter.changeStep(value);
  }

  public getStepsVoiceStrings() {
    return this.adapter.getStepsVoiceStrings();
  }

  public saveAuth(auth: AuthModel) {
    CookieService.set(auth.accessToken, `${CONFIG_APP.ACCESS_TOKEN_COOKIE}`);
    CookieService.set(auth.refreshToken, `${CONFIG_APP.REFRESH_TOKEN_COOKIE}`);
    return this.adapter.saveAuth(auth);
  }

  public toggleMainInsturction(value: boolean) {
    return this.adapter.toggleMainInsturction(value);
  }

  public closeProcessInsturction(value: boolean) {
    return this.adapter.toggleProcessInsturction(value);
  }

  public getRefreshToken() {
    return this.adapter.getRefreshToken();
  }

  public getProgress() {
    return this.adapter.getProgress();
  }

  public setTotalCount(totalCount: number) {
    return this.adapter.setTotalCount(totalCount);
  }
}

export const processController = new ProcessController();
