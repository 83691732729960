import { z } from "zod";
import {  createResponseSchema } from "@/shared/service";
import { CONFIG_APP } from "../../config";
import { CookieService } from "@/shared/service/cookie/CookieService";
import {processController, processStore} from "@/shared/service/proggress";

export const RefreshTokenResponseSchema = z.object({
  tokens: z.object({
    accessToken: z.string(),
    refreshToken: z.string(),
  }),
});

export const RefreshTokenResponse = createResponseSchema(RefreshTokenResponseSchema);

export const getToken = () => {
  const cookieAccessToken = CookieService.get(CONFIG_APP.ACCESS_TOKEN_COOKIE);
  const localStorageAccessToken = processStore.getState().auth?.accessToken;

  return cookieAccessToken ? cookieAccessToken : localStorageAccessToken ? localStorageAccessToken : null;
};

export const getRefreshToken = () => {
  const cookieRefreshToken = CookieService.get(CONFIG_APP.REFRESH_TOKEN_COOKIE);
  const localStorageRefreshToken = processStore.getState().auth?.refreshToken;
  return cookieRefreshToken ? cookieRefreshToken : localStorageRefreshToken ? localStorageRefreshToken : null;
};


export const fetchRefreshToken = () => {
  const url = new URL(`${CONFIG_APP.API_ENDPOINT}/api/auth/refresh`);
  const refreshToken = getRefreshToken();


 return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`,
    },
  })
  .then(response => response.json())
  .then(data => RefreshTokenResponse.parseAsync(data))
  .then(response => {
    if (response.status === 'success' && response.data) {
      const { accessToken, refreshToken } = response.data.tokens;
      processController.updateTokens({ accessToken, refreshToken });

      CookieService.set(accessToken, CONFIG_APP.ACCESS_TOKEN_COOKIE);
      CookieService.set(refreshToken, CONFIG_APP.REFRESH_TOKEN_COOKIE);
      return accessToken ;
    } else {
      CookieService.delete(CONFIG_APP.ACCESS_TOKEN_COOKIE);
      CookieService.delete(CONFIG_APP.REFRESH_TOKEN_COOKIE);
      return null;
    }
  })
}




